$max-media: 1600px;
$big-media: 1200px;
$standard-media: 990px;
$tablet-media: 768px;
$small-media: 640px;
$xsmall-media: 480px;

@mixin mq($args...) {
  $media-type: 'only screen';
  $media-type-key: 'media-type';
  $args: keywords($args);
  $expr: '';

  @if map-has-key($args, $media-type-key) {
    $media-type: map-get($args, $media-type-key);
    $args: map-remove($args, $media-type-key);
  }

  @each $key, $value in $args {
    @if $value {
      $expr: "#{$expr} and (#{$key}: #{$value})";
    }
  }

  @media #{$media-type} #{$expr} {
    @content;
  }
}

// --- screen ------------------------------------------------------------------

@mixin screen($min, $max, $orientation: false) {
  @include mq($min-width: $min, $max-width: $max, $orientation: $orientation) {
    @content;
  }
}

@mixin max-screen($max) {
  @include mq($max-width: $max) {
    @content;
  }
}

@mixin min-screen($min) {
  @include mq($min-width: $min) {
    @content;
  }
}

@mixin screen-height($min, $max, $orientation: false) {
  @include mq($min-height: $min, $max-height: $max, $orientation: $orientation) {
    @content;
  }
}

@mixin max-screen-height($max) {
  @include mq($max-height: $max) {
    @content;
  }
}

@mixin min-screen-height($min) {
  @include mq($min-height: $min) {
    @content;
  }
}

@mixin xsmall {
  $max: $xsmall-media;

  @include mq(
        $min-device-width: 0,
        $max-device-width: $max
    ) {
    @content;
  }
}

$max-media: 1600px;
$big-media: 1200px;
$standard-media: 990px;
$tablet-media: 768px;
$small-media: 640px;
$xsmall-media: 480px;

@mixin mq($args...) {
  $media-type: 'only screen';
  $media-type-key: 'media-type';
  $args: keywords($args);
  $expr: '';

  @if map-has-key($args, $media-type-key) {
    $media-type: map-get($args, $media-type-key);
    $args: map-remove($args, $media-type-key);
  }

  @each $key, $value in $args {
    @if $value {
      $expr: "#{$expr} and (#{$key}: #{$value})";
    }
  }

  @media #{$media-type} #{$expr} {
    @content;
  }
}

// --- screen ------------------------------------------------------------------

@mixin screen($min, $max, $orientation: false) {
  @include mq($min-width: $min, $max-width: $max, $orientation: $orientation) {
    @content;
  }
}

@mixin max-screen($max) {
  @include mq($max-width: $max) {
    @content;
  }
}

@mixin min-screen($min) {
  @include mq($min-width: $min) {
    @content;
  }
}

@mixin screen-height($min, $max, $orientation: false) {
  @include mq($min-height: $min, $max-height: $max, $orientation: $orientation) {
    @content;
  }
}

@mixin max-screen-height($max) {
  @include mq($max-height: $max) {
    @content;
  }
}

@mixin min-screen-height($min) {
  @include mq($min-height: $min) {
    @content;
  }
}

@mixin xsmall {
  $min: $xsmall-media;
  @include mq(
        $min-device-width: $min
    ) {
    @content;
  }
}

@mixin small {
  $min: $small-media;

  @include mq(
        $min-device-width: $min
    ) {
    @content;
  }
}

@mixin tablet {
  $min: $tablet-media;

  @include mq(
        $min-device-width: $min
    ) {
    @content;
  }
}

@mixin standard {
  $min: $standard-media;

  @include mq(
        $min-device-width: $min
    ) {
    @content;
  }
}

@mixin big {
  $min: $big-media;

  @include mq(
        $min-device-width: $min
    ) {
    @content;
  }
}
