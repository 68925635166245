@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,700,700i&amp;subset=latin-ext");
button, [type=button], [type=reset], [type=submit] {
  appearance: none;
  background-color: #673ab7;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-align: center;
  text-decoration: none;
  transition: background-color 200ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
button:hover, [type=button]:hover, [type=reset]:hover, [type=submit]:hover {
  background-color: #522e92;
  color: #fff;
}
button:focus, [type=button]:focus, [type=reset]:focus, [type=submit]:focus {
  outline: 3px solid rgba(103, 58, 183, 0.6);
  outline-offset: 2px;
}
button:disabled, [type=button]:disabled, [type=reset]:disabled, [type=submit]:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
button:disabled:hover, [type=button]:disabled:hover, [type=reset]:disabled:hover, [type=submit]:disabled:hover {
  background-color: #673ab7;
}

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: 0.375em;
  padding: 0;
}

label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.375em;
}

input,
select,
textarea {
  display: block;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 16px;
}

[type=color], [type=date], [type=datetime], [type=datetime-local], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=time], [type=url], [type=week], input:not([type]), textarea {
  appearance: none;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color 200ms ease;
  width: 100%;
}
[type=color]:hover, [type=date]:hover, [type=datetime]:hover, [type=datetime-local]:hover, [type=email]:hover, [type=month]:hover, [type=number]:hover, [type=password]:hover, [type=search]:hover, [type=tel]:hover, [type=text]:hover, [type=time]:hover, [type=url]:hover, [type=week]:hover, input:not([type]):hover, textarea:hover {
  border-color: #b1b1b1;
}
[type=color]:focus, [type=date]:focus, [type=datetime]:focus, [type=datetime-local]:focus, [type=email]:focus, [type=month]:focus, [type=number]:focus, [type=password]:focus, [type=search]:focus, [type=tel]:focus, [type=text]:focus, [type=time]:focus, [type=url]:focus, [type=week]:focus, input:not([type]):focus, textarea:focus {
  border-color: #673ab7;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(103, 58, 183, 0.7);
  outline: none;
}
[type=color]:disabled, [type=date]:disabled, [type=datetime]:disabled, [type=datetime-local]:disabled, [type=email]:disabled, [type=month]:disabled, [type=number]:disabled, [type=password]:disabled, [type=search]:disabled, [type=tel]:disabled, [type=text]:disabled, [type=time]:disabled, [type=url]:disabled, [type=week]:disabled, input:not([type]):disabled, textarea:disabled {
  background-color: #f2f2f2;
  cursor: not-allowed;
}
[type=color]:disabled:hover, [type=date]:disabled:hover, [type=datetime]:disabled:hover, [type=datetime-local]:disabled:hover, [type=email]:disabled:hover, [type=month]:disabled:hover, [type=number]:disabled:hover, [type=password]:disabled:hover, [type=search]:disabled:hover, [type=tel]:disabled:hover, [type=text]:disabled:hover, [type=time]:disabled:hover, [type=url]:disabled:hover, [type=week]:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover {
  border: 1px solid #ddd;
}
[type=color]::placeholder, [type=date]::placeholder, [type=datetime]::placeholder, [type=datetime-local]::placeholder, [type=email]::placeholder, [type=month]::placeholder, [type=number]::placeholder, [type=password]::placeholder, [type=search]::placeholder, [type=tel]::placeholder, [type=text]::placeholder, [type=time]::placeholder, [type=url]::placeholder, [type=week]::placeholder, input:not([type])::placeholder, textarea::placeholder {
  color: #7a7a7a;
}

textarea {
  resize: vertical;
}

[type=checkbox],
[type=radio] {
  display: inline;
  margin-right: 0.375em;
}

[type=file] {
  margin-bottom: 0.75em;
  width: 100%;
}

select {
  margin-bottom: 0.75em;
  width: 100%;
}

[type=checkbox]:focus,
[type=radio]:focus,
[type=file]:focus,
select:focus {
  outline: 3px solid rgba(103, 58, 183, 0.6);
  outline-offset: 2px;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

dl {
  margin: 0;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}

figure {
  margin: 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

table {
  border-collapse: collapse;
  margin: 1.5em 0;
  table-layout: fixed;
  text-align: left;
  width: 100%;
}

thead {
  line-height: 1.2;
  vertical-align: bottom;
}

tbody {
  vertical-align: top;
}

tr {
  border-bottom: 1px solid #ddd;
}

th {
  font-weight: 600;
}

th,
td {
  padding: 0.75em 0.75em 0.75em 0;
}

html {
  color: #212121;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  line-height: 1.2;
  margin: 0 0 0.75em;
}

h1,
h2,
h3 {
  font-weight: 700;
  letter-spacing: -2px;
}

h1 {
  font-size: 3.8146972656em;
}

h2 {
  font-size: 2.44140625em;
}

h3 {
  font-size: 1.953125em;
}

h4 {
  font-size: 1.5625em;
}

h5,
h6 {
  font-size: 1.25em;
}

p {
  margin: 0 0 0.75em;
}

a {
  color: #cddc39;
  text-decoration-skip: ink;
  text-decoration: none;
  transition: color 200ms ease;
}
a:hover {
  color: #9aa52b;
}

hr {
  border-bottom: 1px solid #ddd;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0;
}

::-moz-selection {
  background: #d1c4e9;
  color: white;
}

::selection {
  background: #d1c4e9;
  color: white;
}

html {
  font-size: 14px;
  -webkit-overflow-scrolling: none;
}
@media only screen and (min-device-width: 768px) {
  html {
    font-size: 16px;
  }
}
@media only screen and (min-device-width: 990px) {
  html {
    font-size: 18px;
  }
}

#root {
  background-color: #f7f7f7;
}

.or {
  color: #673ab7;
}

.contentWrapper {
  margin: 0 auto;
  max-width: 1090px;
  width: 90%;
}
.contentWrapper::after {
  clear: both;
  content: "";
  display: block;
}

.wx660x {
  filter: grayscale(100%);
}

.navBar {
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #673ab7;
  background-color: transparent;
  transition: all 50ms 50ms;
}
.navBar .navBarWrapper {
  margin: 0.75rem auto 0.375rem auto;
}
@media only screen and (min-device-width: 768px) {
  .navBar .navBarWrapper {
    margin: 1.5rem auto 0.75rem auto;
  }
}
.navBar .spaceImage {
  height: 100%;
  max-width: 100%;
  width: 100%;
  filter: brightness(1000%);
}
.navBar .logoLink {
  display: block;
  width: 200px;
}
@media only screen and (min-device-width: 480px) {
  .navBar .logoLink {
    float: left;
    width: 180px;
  }
}
@media only screen and (min-device-width: 768px) {
  .navBar .logoLink {
    width: 230px;
  }
}
.navBar .contactLink {
  display: none;
}
@media only screen and (min-device-width: 480px) {
  .navBar .contactLink {
    color: white;
    display: block;
    float: right;
    cursor: pointer;
    margin-top: -0.2rem;
  }
}
.navBar.navBarWhite {
  background-color: rgba(103, 58, 183, 0.9);
  border-bottom: solid rgba(81, 45, 168, 0.9) 1px;
}

.appHeader {
  height: 280px;
  background: #bdbdbd url("../assets/images/futureishere.jpg") bottom center no-repeat;
  background-size: 360px 240px;
}
@media only screen and (min-device-width: 768px) {
  .appHeader {
    background-attachment: fixed;
    height: 460px;
    background-position-y: top;
    background-size: 690px 460px;
  }
}

.appFooter {
  border-top: 1px solid #d4d4d4;
  padding-top: 1rem;
  background-color: white;
  min-height: 5rem;
  clear: both;
}

.footerInside {
  margin: 2rem auto;
  max-width: 700px;
  width: 65%;
}

.footerTools {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
}

.footerLink {
  flex-basis: 2rem;
  flex-grow: 1;
  position: relative;
  margin: 1rem;
  max-height: 3rem;
}
.footerLink img {
  max-height: 3rem;
  height: 100%;
}
.footerLink.tripleLink {
  flex-basis: 5rem;
  flex-grow: 2.5;
}

.tiles,
.podContainer,
.projectContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: row;
  margin-top: 2rem;
}

.tile {
  flex: 1 1 340px;
  position: relative;
  background-color: #e7e7e7;
  margin: 1rem;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.tileHeader {
  padding: 0.333rem 0.5rem;
  background: #673ab7;
  color: #ffffff;
}
.tileHeader h3 {
  margin: 0;
}
.tileHeader h3 span:before, .tileHeader h3 span:after {
  content: " ";
}

.tileInside a {
  color: #212121;
  border-bottom: 0.2rem solid #cddc39;
  background-color: rgba(205, 220, 57, 0.3);
  padding-bottom: 0.2rem;
  cursor: pointer;
}
.tileInside a:before, .tileInside a:after {
  content: " ";
}
.tileInside a:hover {
  background-color: #d1c4e9;
}
.tileInside h4 {
  padding: 0.75rem 1rem 0.33333rem 1rem;
}

.tileBody {
  margin: 1rem;
}

.tileBottom {
  margin-top: auto;
  width: 100%;
}

.tileImage {
  margin-top: auto;
  width: 60%;
  margin: auto;
  display: block;
  margin-bottom: 1rem;
}
.tileImage.wideImage {
  width: 100%;
  margin-bottom: 0;
}

.twitterWrapper {
  max-width: 250px;
  width: 100%;
  margin: 2rem auto;
}

.instaBox {
  background: url("../assets/images/lakeside-background.jpg") bottom center no-repeat;
  background-size: cover;
}
.instaBox .tileHeader {
  background: transparent;
}
.instaBox .tileInside {
  color: #ffffff;
}
.instaBox .tileInside a {
  cursor: pointer;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.3);
  border-bottom-color: #ffffff;
}
.instaBox .tileInside a:hover {
  background-color: #cddc39;
}

.notFoundContainer,
.oneColumn {
  width: 90%;
  max-width: 700px;
  margin: 2rem auto;
}
.notFoundContainer h1,
.notFoundContainer h2,
.notFoundContainer h3,
.notFoundContainer h4,
.notFoundContainer h5,
.notFoundContainer h6,
.oneColumn h1,
.oneColumn h2,
.oneColumn h3,
.oneColumn h4,
.oneColumn h5,
.oneColumn h6 {
  color: #673ab7;
}
.notFoundContainer a,
.oneColumn a {
  color: #212121;
  border-bottom: 0.2rem solid #cddc39;
  background-color: rgba(205, 220, 57, 0.3);
  padding-bottom: 0.2rem;
  cursor: pointer;
}
.notFoundContainer a:before, .notFoundContainer a:after,
.oneColumn a:before,
.oneColumn a:after {
  content: " ";
}
.notFoundContainer a:hover,
.oneColumn a:hover {
  background-color: #d1c4e9;
}

.caisLogo {
  width: 100%;
}
.caisLogo img {
  display: block;
  width: 80%;
  max-width: 220px;
  margin: 1rem auto 2rem auto;
}

.embedContainer {
  width: 100%;
  max-width: 100%;
}
.embedContainer iframe,
.embedContainer twitterwidget {
  margin: 2rem auto !important;
  max-width: 99.9% !important;
}

.pronounce {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.pronounce h5 {
  color: #cddc39;
  font-size: 0.85rem;
  line-height: 1.8;
}
.pronounce .proFlex {
  flex: 1 1 33%;
  margin: 1rem;
}

.stayInTouch {
  margin: 4rem auto 2rem auto;
  max-width: 700px;
  width: 90%;
}
.stayInTouch h1,
.stayInTouch h2,
.stayInTouch h3 {
  color: #673ab7;
}
.stayInTouch h4 {
  color: #cddc39;
}
.stayInTouch h2,
.stayInTouch h4,
.stayInTouch p {
  text-align: center;
}

.form-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.form-buttons .subFlex {
  margin: 1rem;
}
.form-buttons .revue-form-group {
  display: block;
}
.form-buttons .revue-form-group.revue-form-field {
  flex: 3 1 50%;
}
.form-buttons .revue-form-group.revue-submit {
  flex: 1 1 25%;
}

.podIntro,
.projectIntro {
  max-width: 700px;
  width: 90%;
  margin: 2rem auto;
}

.cast {
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  background-color: #e7e7e7;
  margin: 1rem;
  max-width: 400px;
}
.cast h3 {
  color: #673ab7;
  background-color: #cddc39;
  text-align: center;
  margin-bottom: 0;
  border-bottom: 2px solid #512da8;
  padding: 0.333rem 0.2rem;
}
.cast h3 a {
  color: #673ab7;
  cursor: pointer;
}

.inCast,
.projCast,
.projLink,
.projTech {
  margin: 1rem;
}
.inCast a,
.projCast a,
.projLink a,
.projTech a {
  color: #212121;
  border-bottom: 0.2rem solid #cddc39;
  background-color: rgba(205, 220, 57, 0.3);
  padding-bottom: 0.2rem;
  cursor: pointer;
}
.inCast a:before, .inCast a:after,
.projCast a:before,
.projCast a:after,
.projLink a:before,
.projLink a:after,
.projTech a:before,
.projTech a:after {
  content: " ";
}
.inCast a:hover,
.projCast a:hover,
.projLink a:hover,
.projTech a:hover {
  background-color: #d1c4e9;
}

.footCast,
.projLink {
  margin-top: auto;
}

.project {
  flex: 1 1 340px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  position: relative;
  background-color: #e7e7e7;
  margin: 1rem;
  max-width: 500px;
}
.project h3 {
  color: #673ab7;
  background-color: #cddc39;
  text-align: center;
  margin-bottom: 0;
  border-bottom: 2px solid #512da8;
  padding: 0.333rem 0.2rem;
}
.project h3 a {
  color: #673ab7;
  cursor: pointer;
}

.projectImage {
  position: relative;
}
.projectImage .over {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(189, 189, 189, 0.4);
}

