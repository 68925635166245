// Typography
$base-font-family: $font-stack-system;
$heading-font-family: $base-font-family;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: 1.5em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #1565c0;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;
$niereb-main: #ff3377;
$niereb-secondary: #ff7733;
$niereb-gray: #777777;

// $primary-color-dark: #e64a19;
// $primary-color: #ff5722;
// $primary-color-light: #ffccbc;
// $primary-color-text: #ffffff;
// $accent-color: #448aff;
// $primary-text-color: #212121;
// $secondary-text-color: #757575;
// $divider-color: #bdbdbd;

$primary-color-dark: #512da8;
$primary-color: #673ab7;
$primary-color-light: #d1c4e9;
$primary-color-text: #ffffff;
$accent-color: #cddc39;
$primary-text-color: #212121;
$secondary-text-color: #757575;
$divider-color: #bdbdbd;

$app-width: 1090px;
// Font Colors
$base-font-color: $primary-text-color;
$action-color: $primary-color;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: white;
$secondary-background-color: tint($base-border-color, 75%);

// Focus
$focus-outline-color: transparentize($action-color, 0.4);
$focus-outline-width: 3px;
$focus-outline: $focus-outline-width solid $focus-outline-color;
$focus-outline-offset: 2px;

// Animations
$base-duration: 200ms;
$base-timing: ease;
