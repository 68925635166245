html {
  color: $base-font-color;
  font-family: $base-font-family;
  // font-size: 17px; - just to remember
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

h1,
h2,
h3 {
  font-weight: 700;
  letter-spacing: -2px;
}

h1 {
  font-size: modular-scale(6);
}
h2 {
  font-size: modular-scale(4);
}
h3 {
  font-size: modular-scale(3);
}
h4 {
  font-size: modular-scale(2);
}
h5,
h6 {
  font-size: modular-scale(1);
}
p {
  margin: 0 0 $small-spacing;
}

a {
  color: $accent-color;
  text-decoration-skip: ink;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:hover {
    color: shade($accent-color, 25%);
  }
  // left for remembrance!
  // &:focus {
  //   outline: $focus-outline;
  //   outline-offset: $focus-outline-offset;
  // }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}
