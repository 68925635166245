@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,700,700i&amp;subset=latin-ext');
@import '../dependencies/bourbon/bourbon';
@import '../dependencies/base/base';
@import '../dependencies/myVars/media';

$mugshot: '../assets/images/futureishere.jpg';

$lakeside: '../assets/images/lakeside-background.jpg';

$mugshot-color: $divider-color;
$grid-color: transparentize(#0ee, 0.8);
$space-character: '\00a0';

$standard-grid: (columns: 12, gutter: 2rem, media: $standard-media);
$tablet-grid: (columns: 12, gutter: 1rem, media: $tablet-media);
$small-grid: (columns: 12, gutter: 0.5rem, media: $small-media);
$navbar-height: 3rem;
$selection-color: $primary-color-light;
$content-background-color: #f7f7f7;
$accent-light-color: transparentize($accent-color, 0.7);

::-moz-selection {
  background: $selection-color;
  color: white;
}
::selection {
  background: $selection-color;
  color: white;
}

html {
  font-size: 14px;
  -webkit-overflow-scrolling: none;
  @include tablet {
    font-size: 16px;
  }
  @include standard {
    font-size: 18px;
  }
}

#root {
  background-color: $content-background-color;
}
.or {
  color: $primary-color;
}
@mixin clear {
  clear: both;
  content: "";
  display: block;
}

.contentWrapper {
  margin: 0 auto;
  max-width: $app-width;
  width: 90%;
  &::after {
    @include clear;
  }
}

.wx660x {
  filter: grayscale(100%);
}

.navBar {
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: $action-color;
  background-color: transparent;
  transition: all 50ms 50ms;
  .navBarWrapper {
    margin: $navbar-height/4 auto $navbar-height/8 auto;
    @include tablet {
      margin: $navbar-height/2 auto $navbar-height/4 auto;
    }
  }
  .spaceImage {
    height: 100%;
    max-width: 100%;
    width: 100%;
    filter: brightness(1000%);
  }
  .logoLink {
    display: block;
    width: 200px;
    @include xsmall {
      float: left;
      width: 180px;
    }
    @include tablet {
      width: 230px;
    }
  }

  .contactLink {
    display: none;
    @include xsmall {
      color: white;
      display: block;
      float: right;
      cursor: pointer;
      margin-top: -.2rem;
    }
  }

  &.navBarWhite {
    background-color: transparentize($primary-color, 0.1);
    border-bottom: solid transparentize($primary-color-dark, 0.1) 1px;
  }
}

.appHeader {
  height: 280px;
  background: $mugshot-color url($mugshot) bottom center no-repeat;
  background-size: 360px 240px;
  @include tablet {
    background-attachment: fixed;
    height: 460px;
    background-position-y: top;
    background-size: 690px 460px;
  }
}

.appFooter {
  border-top: 1px solid lighten($base-font-color, 70%);
  padding-top: 1rem;
  background-color: white;
  min-height: 5rem;
  clear: both;
}

.footerInside {
  margin: 2rem auto;
  max-width: 700px;
  width: 65%;
}

.footerTools {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
}

.footerLink {
  flex-basis: 2rem;
  flex-grow: 1;
  position: relative;
  margin: 1rem;
  max-height: 3rem;
  img {
    max-height: 3rem;
    height: 100%;
  }
  &.tripleLink {
    flex-basis: 5rem;
    flex-grow: 2.5;
  }
}

.tiles,
.podContainer,
.projectContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: row;
  margin-top: 2rem;
}

.tile {
  $tile-background: #e7e7e7;
  flex: 1 1 340px;

  position: relative;
  background-color: $tile-background;
  margin: 1rem;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.tileHeader {
  padding: 0.333rem 0.5rem;
  background: $primary-color;
  color: $primary-color-text;
  h3 {
    margin: 0;
    span {
      // border-bottom: $action-color solid .25rem;
      &:before,
      &:after {
        content: $space-character;
      }
    }
  }
}

@mixin nicelink() {
  a {
    color: $base-font-color;
    border-bottom: 0.2rem solid $accent-color;
    background-color: $accent-light-color;
    padding-bottom: 0.2rem;
    cursor: pointer;
    &:before,
    &:after {
      content: $space-character;
    }

    &:hover {
      background-color: $selection-color;
    }
  }
}
.tileInside {
  @include nicelink;

  h4 {
    padding: 0.75rem 1rem 0.33333rem 1rem;
  }
}

.tileBody {
  margin: 1rem;
}
.tileBottom {
  margin-top: auto;
  width: 100%;
}
.tileImage {
  margin-top: auto;
  width: 60%;
  margin: auto;
  display: block;
  margin-bottom: 1rem;
  &.wideImage {
    width: 100%;
    margin-bottom: 0;
  }
}

.twitterWrapper {
  max-width: 250px;
  width: 100%;
  margin: 2rem auto;
}

.instaBox {
  background: url($lakeside) bottom center no-repeat;
  background-size: cover;
  .tileHeader {
    background: transparent;
  }
  .tileInside {
    color: $primary-color-text;
    a {
      cursor: pointer;
      color: $primary-color-text;
      background-color: transparentize($primary-color-text, 0.7);
      border-bottom-color: $primary-color-text;

      &:hover {
        background-color: $accent-color;
      }
    }
  }
}

.notFoundContainer,
.oneColumn {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $primary-color;
  }
  width: 90%;
  max-width: 700px;
  margin: 2rem auto;
  @include nicelink;
}

.caisLogo {
  width: 100%;
  img {
    display: block;
    width: 80%;
    max-width: 220px;
    margin: 1rem auto 2rem auto;
  }
}

.embedContainer {
  width: 100%;
  max-width: 100%;
  iframe,
  twitterwidget {
    margin: 2rem auto !important;
    max-width: 99.9% !important;
  }
}

.pronounce {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h5 {
    color: $accent-color;
    font-size: 0.85rem;
    line-height: 1.8;
  }
  .proFlex {
    flex: 1 1 33%;
    margin: 1rem;
  }
}

.stayInTouch {
  h1,
  h2,
  h3 {
    color: $primary-color;
  }
  h4 {
    color: $accent-color;
  }
  h2,
  h4,
  p {
    text-align: center;
  }
  margin: 4rem auto 2rem auto;
  max-width: 700px;
  width: 90%;
}

.form-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  .subFlex {
    margin: 1rem;
  }

  .revue-form-group {
    display: block;
    &.revue-form-field {
      // margin-bottom: 0 !important;
      flex: 3 1 50%;
    }
    &.revue-submit {
      flex: 1 1 25%;
    }
  }
}

.podIntro,
.projectIntro {
  max-width: 700px;
  width: 90%;
  margin: 2rem auto;
}

.cast {
  $tile-background: #e7e7e7;
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  background-color: $tile-background;
  margin: 1rem;
  max-width: 400px;
  h3 {
    color: $primary-color;
    background-color: $accent-color;
    text-align: center;
    margin-bottom: 0;
    border-bottom: 2px solid $primary-color-dark;
    padding: .333rem 0.2rem;
    a {
      color: $primary-color;
      cursor: pointer;
    }
  }
}
.inCast,
.projCast,
.projLink,
.projTech {
  margin: 1rem;

  @include nicelink;
}
.footCast,
.projLink {
  margin-top: auto;
}

.project {
  $tile-background: #e7e7e7;
  flex: 1 1 340px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  position: relative;
  background-color: $tile-background;
  margin: 1rem;
  max-width: 500px;
  h3 {
    color: $primary-color;
    background-color: $accent-color;
    text-align: center;
    margin-bottom: 0;
    border-bottom: 2px solid $primary-color-dark;
    padding: .333rem 0.2rem;
    a {
      color: $primary-color;
      cursor: pointer;
    }
  }
}

.projectImage {
  position: relative;
  .over {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: transparentize($divider-color, 0.6);
  }
}
